import React from 'react'
import styled from 'styled-components'

import { device } from '../components/Styles'
import createTaskImg from '../images/screenshots/create-task.png'
import buildUI from '../images/screenshots/build-ui.png'
import approval from '../images/screenshots/approval.png'
import logs from '../images/screenshots/logs.png'

const Root = styled.div`
  margin: 0 auto;
  @media ${device.laptop} {
    width: var(--width);
  }
`
const Feature = styled.div`
  text-align: center;
  font-size: 1.4rem;
  margin-bottom: 4rem;

  h2 {
    font-weight: 800;
  }

  p {
    line-height: 1.5em;
  }
`
const ImageContainer = styled.div`
  max-width: 100%;
`

const Left = styled.div`
  padding: 1rem;
`
const Right = styled.div``

const ProductFeatures = (): React.ReactElement => {
  return (
    <Root>
      <h1>Product</h1>

      <Feature>
        <Left>
          <h2>Create tasks</h2>
          <p>Simply connect Backfill to your existing Git repos that contain your engineering scripts</p>
        </Left>
        <Right>
          <ImageContainer>
            <img src={createTaskImg} />
          </ImageContainer>
        </Right>
      </Feature>

      <Feature>
        <Left>
          <h2>Build an interface for your script</h2>
          <p>
            If your script takes parameters, you can build a friendly UI which end users can use rather than having to
            use CLI incantations
          </p>
        </Left>
        <Right>
          <ImageContainer>
            <img src={buildUI} />
          </ImageContainer>
        </Right>
      </Feature>

      <Feature>
        <Left>
          <h2>Permissions &amp; approvals</h2>
          <p>
            Enable approvals so that scripts can only be run after sign-off by a manager or other engineer. Turn on
            permissions so that scripts are only available to specific engineering teams.
          </p>
        </Left>
        <Right>
          <ImageContainer>
            <img src={approval} />
          </ImageContainer>
        </Right>
      </Feature>

      <Feature>
        <Left>
          <h2>Logs &amp; notifications</h2>
          <p>All log output from your scripts is saved and searchable. Get notified when your task finishes running.</p>
        </Left>
        <Right>
          <ImageContainer>
            <img src={logs} />
          </ImageContainer>
        </Right>
      </Feature>
    </Root>
  )
}

export default ProductFeatures
