/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from 'react'
import styled from 'styled-components'

import '../reset.css'
import '../variables.css'
import '../index.css'
import '../prismjs.css'

import Header from './Header'
import Footer from './Footer'
import { device } from './Styles'

const Content = styled.div``
const AboveTheFold = styled.div`
  background-color: var(--color-slate-900);
  margin-bottom: 4rem;
  padding: 1rem;

  @media ${device.laptop} {
    padding: 0;
  }
`

interface Props {
  children: React.ReactNode
  aboveTheFold?: React.ReactNode
}

const Layout: React.FC<Props> = ({ children, aboveTheFold }) => {
  return (
    <>
      <AboveTheFold>
        <Header />
        {aboveTheFold}
      </AboveTheFold>
      <Content>
        <main>{children}</main>
      </Content>
      <Footer />
    </>
  )
}

export default Layout
