import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'
import { device } from './Styles'

import logo from '../images/logo_white.png'

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 1rem 0;
  margin: 0 auto;
  align-items: center;
  color: #fff;

  @media ${device.laptop} {
    width: var(--width);
    padding: 2rem 0;
  }
`

const Menu = styled.div`
  > * {
    margin-left: 1rem;
    margin-right: 1rem;
    display: inline-block;
  }
`

const linkStyle = css`
  text-decoration: none;
  color: var(--color-gray-200);
  transition: text-shadow 0.2s;
  cursor: pointer;

  &:hover {
    color: var(--color-pink-400);
  }
`

const StyledLink = styled(Link)`
  ${linkStyle}
`

const StyledNormalLink = styled.a`
  ${linkStyle}
`

const Logo = styled.img`
  width: 128px;
  display: inline-block;
`

const live = true

const Header = () => (
  <Root>
    <div>
      <Link to={'/'}>
        <Logo src={logo} />
      </Link>
    </div>
    {live ? (
      <Menu>
        <StyledLink to={`/`}>Home</StyledLink>
        <StyledLink to={`/pricing`}>Pricing</StyledLink>
        <StyledLink to={`/blog`}>Blog</StyledLink>
        <StyledNormalLink href={`https://app.backfill.dev`}>Login</StyledNormalLink>
      </Menu>
    ) : null}
  </Root>
)

export default Header
