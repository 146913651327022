import React from 'react'
import styled, { css } from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faScrewdriverWrench,
  faBriefcase,
  faPager,
  faClipboardList,
  faLock,
  faClock,
  faWindow,
  faBell
} from '@fortawesome/pro-light-svg-icons'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import SEO from '../components/Seo'
import { device } from '../components/Styles'
import ProductFeatures from '../components/ProductFeatures'

const Hero = styled.div`
  margin: 0 auto;
  position: relative;
  z-index: 99;
  text-align: center;

  @media ${device.laptop} {
    width: var(--width);
    padding: 2rem;
  }
`

const titleSharedCssSmallDevice = css`
  font-size: 48px;
  font-weight: 800;
  line-height: 1em;
  letter-spacing: -2.4px;
`

const titleSharedCss = css`
  font-size: 64px;
  line-height: 1em;
`

const TitleWhite = styled.h1`
  display: inline;
  color: #fff;
  margin: 0;
  ${titleSharedCssSmallDevice}

  @media ${device.laptop} {
    ${titleSharedCss}
  }
`

const Title = styled.h1`
  background-image: linear-gradient(to right, rgb(239, 68, 68), rgb(59, 130, 246));
  -webkit-background-clip: text;
  -moz-background-clip: text;
  -webkit-text-fill-color: transparent;
  -moz-text-fill-color: transparent;
  display: inline;
  margin-right: 0.25rem;

  ${titleSharedCssSmallDevice}

  @media ${device.laptop} {
    ${titleSharedCss}
  }
`

const Subtitle = styled.div`
  margin-bottom: 0.5rem;
  font-size: 1.4rem;
`

const UseCaseSection = styled.div`
  padding: 1rem;
  margin: 5rem 0 5rem 0;

  h1,
  h2 {
    text-align: center;
  }
  h2 {
    color: var(--color-gray-600);
  }
`

const ExamplesList = styled.div`
  display: grid;
  justify-content: center;
  grid-template-columns: 100%;
  grid-gap: 1rem;

  @media ${device.laptop} {
    max-width: 960px;
    margin: 0 auto;
    grid-template-columns: 300px 300px 300px;
    grid-gap: 1rem;
  }
`

const Example = styled.div`
  padding: 2rem 2rem;
  background-color: var(--color-gray-700);
  margin: 1rem;
  border-radius: 0.4rem;
  color: var(--color-gray-300);

  h2 {
    color: var(--color-gray-50);
    text-align: left;
  }

  @media ${device.laptop} {
  }
`

const Section = styled.div`
  margin: 5rem auto;
  padding: 1rem;
  text-align: center;

  @media ${device.laptop} {
    width: var(--width);
    padding: 0;
  }

  h1 {
    text-align: center;
  }
`
const Icon = styled.div`
  color: #fff;
  font-size: 1.2rem;
  margin-bottom: 1rem;
`
const GetEarlyAccess = styled(Link)`
  font-size: 1.4rem;
  text-decoration: none;
  padding: 1rem 2rem;
  background-color: rgb(72 128 237);
  color: #fff;
  font-weight: 600;
  border-radius: 3rem;
  margin-top: 2rem;
`

const IndexPage = (): React.ReactElement => (
  <Layout
    aboveTheFold={
      <Hero>
        <Title>Streamline</Title> <TitleWhite>how you run engineering scripts on production</TitleWhite>
        <Subtitle></Subtitle>
      </Hero>
    }
  >
    <SEO title="Turn code into internal apps" />

    <Section>
      <GetEarlyAccess to="/early-access">Get early access</GetEarlyAccess>
    </Section>

    <UseCaseSection>
      <h1>Use cases</h1>
      <ExamplesList>
        <Example>
          <Icon>
            <FontAwesomeIcon icon={faScrewdriverWrench} />
          </Icon>
          <h2>Engineering operations</h2>
          <p>Let your engineers run scripts against your production or pre-prod servers securely.</p>
        </Example>

        <Example>
          <Icon>
            <FontAwesomeIcon icon={faBriefcase} />
          </Icon>
          <h2>Business operations</h2>
          <p>
            Quickly create custom UI for your business ops teams to enable them to run scripts without requiring
            technical knowledge.
          </p>
        </Example>

        <Example>
          <Icon>
            <FontAwesomeIcon icon={faPager} />
          </Icon>
          <h2>On call</h2>
          <p>Allow on-call engineers to easily run scripts to troubleshoot or fix live issues.</p>
        </Example>
      </ExamplesList>
    </UseCaseSection>

    <UseCaseSection>
      <h1>Benefits</h1>
      <ExamplesList>
        <Example>
          <Icon>
            <FontAwesomeIcon icon={faClipboardList} />
          </Icon>
          <h2>Approval flows</h2>
          <p>Allow managers to sign off on sensitive tasks.</p>
        </Example>

        <Example>
          <Icon>
            <FontAwesomeIcon icon={faLock} />
          </Icon>
          <h2>Audit logs</h2>
          <p>Track precisly when a script was run, and by who.</p>
        </Example>

        <Example>
          <Icon>
            <FontAwesomeIcon icon={faClock} />
          </Icon>
          <h2>Long running tasks</h2>
          <p>Scripts are run in the cloud, no need to keep your laptop open and wait until it finishes.</p>
        </Example>

        <Example>
          <Icon>
            <FontAwesomeIcon icon={faWindow} />
          </Icon>
          <h2>UI builder</h2>
          <p>Quickly create a frontend for your engineering scripts to make interacting with them effortless.</p>
        </Example>

        <Example>
          <Icon>
            <FontAwesomeIcon icon={faBell} />
          </Icon>
          <h2>Notifications</h2>
          <p>Get email or slack notifications when your task has completed running.</p>
        </Example>
      </ExamplesList>
    </UseCaseSection>

    <UseCaseSection>
      <ProductFeatures />
    </UseCaseSection>
  </Layout>
)

export default IndexPage
