import React from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { device } from './Styles'

const Root = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 4rem auto;
  align-items: flex-start;
  padding: 1rem;

  @media ${device.laptop} {
    width: var(--width);
    padding: 0;
  }
`

const Menu = styled.div``

const Item = styled.div`
  color: var(--color-gray-500);
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: var(--color-gray-500);
  transition: text-shadow 0.2s;

  &:hover {
    color: var(--color-pink-400);
  }

  margin-right: 1rem;
`

const Footer = () => (
  <Root>
    <Menu>
      <Item>Copyright {new Date().getFullYear()}</Item>
    </Menu>
    <Menu>
      <span>Legal</span>
      <Item>
        <StyledLink to={'/terms'}>Terms</StyledLink>
      </Item>
      <Item>
        <StyledLink to={'/privacy'}>Privacy</StyledLink>
      </Item>
    </Menu>
  </Root>
)

export default Footer
